export const state = () => ({
  loginRedirectRoute: '/psi/fci-klasifikace',
  navItems: [
    {
      to: '/psi/',
      content: 'Psi',
      navItems: [
        {
          to: '/psi/psi',
          content: 'Psi',
          permission: 'dogs.dogs.index'
        },
        {
          to: '/psi/fci-klasifikace',
          content: 'Fci klasifikace',
          permission: 'dogs.fci_classifications.index'
        },
        {
          to: '/psi/plemena',
          content: 'Plemena',
          permission: 'dogs.breeds.index'
        }
      ]
    },
    {
      to: '/subjekty/',
      content: 'Subjekty',
      navItems: [
        {
          to: '/subjekty/veterinari',
          content: 'Veterináři',
          permission: 'subjects.veterinaries.index'
        },
        {
          to: '/subjekty/salony',
          content: 'Salony',
          permission: 'subjects.salons.index'
        },
        {
          to: '/subjekty/kluby',
          content: 'Kluby',
          permission: 'subjects.clubs.index'
        },
        {
          to: '/subjekty/utulky',
          content: 'Útulky',
          permission: 'subjects.shelters.index'
        }
      ]
    },
    {
      to: '/nastaveni/',
      content: 'Nastavení',
      navItems: [
        {
          to: '/nastaveni/aktivity',
          content: 'Aktivity',
          permission: 'settings.activities.index'
        },
        {
          to: '/nastaveni/zeme',
          content: 'Země',
          permission: 'settings.countries.index'
        },
        {
          to: '/nastaveni/meny',
          content: 'Měny',
          permission: 'settings.currencies.index'
        },
        {
          to: '/nastaveni/jazyky',
          content: 'Jazyky',
          permission: 'settings.languages.index'
        },
        {
          to: '/nastaveni/stitky',
          content: 'Štítky',
          permission: 'settings.tags.index'
        },
        {
          to: '/nastaveni/uzivatele',
          content: 'Uživatelé',
          permission: 'users.users.index'
        }
      ]
    }
  ]
})

export const getters = {
  getNavItems (state) {
    return state.navItems
  }
}
