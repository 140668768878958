export default (ctx, inject) => {
  inject('forms', {
    exam: vm => ({
      fields: [
        {
          name: 'title',
          label: 'Název',
          type: 'text',
          rules: 'required'
        },
        {
          name: 'description',
          label: 'Popis',
          type: 'textarea'
        },
        {
          name: 'time_limit',
          label: 'Časový limit v minutách',
          type: 'number',
          rules: 'required|numeric'
        },
        {
          name: 'required_percent',
          label: 'Požadovaná úspěšnost v %',
          type: 'number',
          rules: 'required|numeric|min_value:1|max_value:100'
        },
        {
          name: 'attempts',
          label: 'Počet pokusů',
          type: 'number',
          rules: 'required|numeric|min_value:1'
        },
        {
          name: 'cheating_control',
          label: 'Ochrana proti podvádění',
          type: 'switch'
        },
        vm.$helpers.showIf(
          vm.model?.cheating_control,
          {
            name: 'cheating_warnings',
            label: 'Počet upozornění před zablokováním testu',
            type: 'number',
            rules: 'required|numeric'
          }
        ),
        vm.$helpers.showIf(
          vm.model?.cheating_control,
          {
            name: 'right_click_block',
            label: 'Blokace pravého tlačítka myši',
            type: 'switch'
          }
        ),
        {
          name: 'sections',
          label: 'Sekce',
          type: 'renderless'
        }
      ].filter(Boolean),
      config: {
        newItemLabel: 'Nový test',
        editItemLabel: 'Úprava testu',
        url: 'exams',
        permission: 'exams',
        recaptcha: 'exam'
      }
    }),
    examSection: vm => ({
      fields: [
        {
          name: 'title',
          label: 'Název',
          type: 'text',
          rules: 'required'
        },
        {
          name: 'subtitle',
          label: 'Podnadpis',
          type: 'text'
        },
        {
          name: 'type',
          label: 'Typ',
          type: 'select',
          rules: 'required',
          select: {
            label: 'text',
            trackBy: 'value',
            options: [
              { text: 'Text', value: 'text' },
              { text: 'Obrázek', value: 'image' },
              { text: 'Audio', value: 'audio' },
              { text: 'Video', value: 'video' }
            ]
          }
        },
        vm.$helpers.showIf(
          vm.model?.type === 'image',
          {
            name: 'file_image',
            label: 'Obrázek',
            type: 'file',
            rules: 'required',
            accept: 'image/jpeg, image/png, image/gif, image/webp, image/svg+xml'
          }
        ),
        vm.$helpers.showIf(
          vm.model?.type === 'audio',
          {
            name: 'file_audio',
            label: 'Audio',
            type: 'file',
            rules: 'required',
            accept: 'audio/mpeg, audio/wav, audio/ogg, audio/aac, audio/webm, audio/flac'
          }
        ),
        vm.$helpers.showIf(
          vm.model?.type === 'video',
          {
            name: 'file_video',
            label: 'Video',
            type: 'file',
            rules: 'required',
            accept: 'video/mp4, video/webm, video/ogg, video/mpeg, video/quicktime, video/x-ms-wmv'
          }
        ),
        {
          name: 'easy_count',
          label: 'Počet losovaných jednoduchých',
          type: 'range',
          rules: 'required|numeric',
          max: vm.item?.easy_sum
        },
        {
          name: 'intermediate_count',
          label: 'Počet losovaných středně těžkých',
          type: 'range',
          rules: 'required|numeric',
          max: vm.item?.intermediate_sum
        },
        {
          name: 'hard_count',
          label: 'Počet losovaných těžkých',
          type: 'range',
          rules: `required|numeric|max_value:${vm.item?.hard_sum}`,
          max: vm.item?.hard_sum
        }
      ].filter(Boolean),
      config: {
        newItemLabel: 'Nová sekce',
        editItemLabel: 'Úprava sekce',
        url: `exams/${vm.examId}/sections`,
        permission: 'exam.sections',
        recaptcha: 'exam_section'
      }
    }),
    examStudent: vm => ({
      fields: [
        {
          name: 'fullname',
          label: 'Název',
          type: 'text',
          rules: 'required'
        }
      ].filter(Boolean),
      config: {
        newItemLabel: 'Přidat studenta',
        url: `exams/${vm.examId}/examStudents`,
        permission: 'exam.students',
        recaptcha: 'exam_student'
      }
    }),
    examQuestion: vm => ({
      fields: [
        {
          name: 'title',
          label: 'Otázka',
          type: 'text',
          rules: 'required'
        },
        {
          name: 'difficulty',
          label: 'Obtížnost',
          type: 'radio_group',
          rules: 'required',
          radio: {
            options: [
              { text: 'Jednoduchá', value: 'easy' },
              { text: 'Středně těžká', value: 'intermediate' },
              { text: 'Těžká', value: 'hard' }
            ],
            buttons: true
          }
        },
        {
          name: 'type',
          label: 'Typ',
          type: 'select',
          rules: 'required',
          select: {
            label: 'text',
            trackBy: 'value',
            options: [
              { text: 'Text', value: 'text' },
              { text: 'Obrázek', value: 'image' },
              { text: 'Audio', value: 'audio' },
              { text: 'Video', value: 'video' }
            ]
          }
        },
        vm.$helpers.showIf(
          vm.model?.type === 'image',
          {
            name: 'file_image',
            label: 'Obrázek',
            type: 'file',
            rules: 'required',
            accept: 'image/jpeg, image/png, image/gif, image/webp, image/svg+xml'
          }
        ),
        vm.$helpers.showIf(
          vm.model?.type === 'audio',
          {
            name: 'file_audio',
            label: 'Audio',
            type: 'file',
            rules: 'required',
            accept: 'audio/mpeg, audio/wav, audio/ogg, audio/aac, audio/webm, audio/flac'
          }
        ),
        vm.$helpers.showIf(
          vm.model?.type === 'video',
          {
            name: 'file_video',
            label: 'Video',
            type: 'file',
            rules: 'required',
            accept: 'video/mp4, video/webm, video/ogg, video/mpeg, video/quicktime, video/x-ms-wmv'
          }
        ),
        {
          name: 'answer_type',
          label: 'Typ odpovědi',
          type: 'select',
          rules: 'required',
          select: {
            options: [
              { text: 'Vyberte správnou odpověď', value: 'choose-correct' },
              { text: 'Doplňte do textu', value: 'fill-to-text' },
              { text: 'Přiřaďte', value: 'assign' }
            ]
          }
        },
        vm.$helpers.showIf(
          vm.model?.answer_type === 'fill-to-text',
          {
            name: 'title_after',
            label: 'Zakončení otázky',
            type: 'text'
          }
        ),
        vm.$helpers.showIf(
          vm.model?.answer_type === 'fill-to-text',
          {
            name: 'space_before',
            label: 'Mezera před doplněním',
            type: 'switch'
          }
        ),
        vm.$helpers.showIf(
          vm.model?.answer_type === 'fill-to-text',
          {
            name: 'space_after',
            label: 'Mezera za doplněním',
            type: 'switch'
          }
        ),
        vm.$helpers.showIf(
          vm.model?.answer_type === 'fill-to-text',
          {
            name: 'title_complete'
          }
        ),
        {
          name: 'answers',
          label: 'Odpovědi',
          type: 'textarea',
          rules: null
        }
      ].filter(Boolean),
      config: {
        newItemLabel: 'Nová otázka',
        editItemLabel: 'Úprava otázky',
        url: `exams/sections/${vm.sectionId}/questions`,
        permission: 'exam.section.questions',
        recaptcha: 'exam_section_question'
      }
    }),
    student: vm => ({
      fields: [
        {
          name: 'degree',
          label: 'Titul',
          type: 'text',
          rules: null
        },
        {
          name: 'firstname',
          label: 'Jméno',
          type: 'text',
          rules: 'required'
        },
        {
          name: 'surname',
          label: 'Příjmení',
          type: 'text',
          rules: 'required'
        },
        {
          name: 'degree_after',
          label: 'Titul za',
          type: 'text',
          rules: null
        },
        {
          name: 'email',
          label: 'E-mail',
          type: 'email',
          rules: 'email'
        },
        {
          name: 'phone',
          label: 'Telefon',
          type: 'text',
          rules: null
        },
        {
          name: 'note',
          label: 'Poznámka',
          type: 'textarea',
          rules: null
        },
        {
          section: true,
          class: 'alert alert-info px-3 pt-2 pb-0 mt-4',
          name: 'login',
          label: 'Přihlašovací údaje',
          fields: [
            {
              name: 'email',
              label: 'E-mail',
              type: 'email',
              rules: 'required|email'
            },
            {
              name: 'password',
              label: 'Heslo',
              editLabel: 'Upravit heslo',
              type: 'password',
              rules: { required: true, min: 6, regex: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/ },
              editRules: { min: 6, regex: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/ }
            },
            {
              name: 'banned',
              label: 'Zabanován',
              type: 'switch'
            }
          ]
        }
      ],
      config: {
        newItemLabel: 'Nový student',
        editItemLabel: 'Úprava studenta',
        url: 'students',
        permission: 'students',
        recaptcha: 'student'
      }
    }),
    administrator: vm => ({
      fields: [
        {
          name: 'name',
          label: 'Jméno',
          type: 'text',
          rules: 'required'
        },
        {
          name: 'email',
          label: 'E-mail',
          type: 'email',
          rules: 'required'
        },
        {
          name: 'password',
          label: 'Heslo',
          type: 'password',
          rules: 'required',
          editable: false
        }
      ],
      config: {
        newItemLabel: 'Nový uživatel',
        editItemLabel: 'Úprava uživatele',
        url: 'users',
        permission: 'users.users',
        recaptcha: 'users/users'
      }
    }),
    activity: vm => ({
      config: {
        url: 'settings/activities',
        permission: 'settings.activities'
      }
    })
  })
}
