export default (ctx, inject) => {
  inject('tables', {
    exam: () => ([
      {
        key: 'title',
        label: 'Název',
        sortable: false,
        width: '400px'
      },
      {
        key: 'required_percent',
        label: 'Náročnost',
        sortable: false,
        width: '400px'
      },
      {
        key: 'actions',
        label: 'Akce',
        class: 'text-center',
        width: '200px'
      }
    ]),
    examSection: () => ([
      {
        key: 'title',
        label: 'Název',
        sortable: false,
        width: '400px'
      },
      {
        key: 'type',
        label: 'Typ sekce',
        sortable: false,
        width: '200px'
      },
      {
        key: 'questions_count',
        label: 'Otázek',
        sortable: false,
        width: '100px'
      },
      {
        key: 'actions',
        label: 'Akce',
        class: 'text-center',
        width: '200px'
      }
    ]),
    examStudent: () => ([
      {
        key: 'fullname',
        label: 'Jméno',
        sortable: false,
        width: '400px'
      },
      {
        key: 'attempts',
        label: 'Pokusů',
        sortable: false,
        width: '400px'
      },
      {
        key: 'actions',
        label: 'Akce',
        class: 'text-center',
        width: '200px'
      }
    ]),
    examQuestion: () => ([
      {
        key: 'title',
        label: 'Název',
        sortable: false,
        width: '400px'
      },
      {
        key: 'type',
        label: 'Typ otázky',
        sortable: false,
        width: '200px'
      },
      {
        key: 'answer_type',
        label: 'Typ odpovědi',
        sortable: false,
        width: '200px'
      },
      {
        key: 'answers_count',
        label: 'Odpovědí',
        sortable: false,
        width: '100px'
      },
      {
        key: 'actions',
        label: 'Akce',
        class: 'text-center',
        width: '200px'
      }
    ])
  })
}
